<template>
  <v-layout
  align-center
  justify-center>

    <v-flex
        xs10
        sm6
        md4
      >
      <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
      <v-form @submit.prevent="passes(send)">
        <ValidationProvider name="Pedido de material" rules="required" v-slot="{ errors, valid }">
        <v-textarea
          outlined
          v-model="detalle"
          name="pedido"
          label="Detalles del pedido"
          :success="valid"
          :error-messages="errors"
          required
        ></v-textarea>
        </ValidationProvider>


        <div align-self-center class="mt-1">
          <v-btn type="submit" block @click.prevent="passes(pedido)" color="primary darken-3"
            dark :loading="loading" :disabled="loading">Enviar</v-btn>
        </div>
      </v-form>
      </ValidationObserver>
    </v-flex>

  </v-layout>

</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    loading: false,
    detalle: '',
  }),

 methods:{
  pedido: function () {
      this.loading = true

      this.$soapClient.call('pedidoDeMaterial', {
        cuerpo: this.detalle
      })

      .then((response) => {
        let resultado = response.data.resultado

        this.$eventHub.$emit('snackbar-message', resultado, 'success')

        this.$refs.obs.reset();
      })
      .catch((error) => {
        if ('message' in error) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        }
      })
      this.loading = false
    }
  }
};

</script>
